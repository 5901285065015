import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogRef, NbDialogService, NbMenuService, NbToastrService } from '@nebular/theme';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OrganisationComponentBase } from '../../org-base-component';
import { DataEndpoint } from '../../services/eapi-data-services/data-endpoint/data-endpoint';

import { GDS } from '../../services/gds.service';
import { OrganisationComponent } from '../organisation/organisation.component';


@Component({
  selector: 'ngx-secret-input',
  templateUrl: './secret-input.component.html',
  styleUrls: ['./secret-input.component.scss']
})
export class SecretInputComponent implements OnInit,OnDestroy {
  @Input() org: any;
  @Input() gds: any;

  organisation: any = [];

  unlockKey : any;
  isAddNewOrgButtonClicked: boolean = false;

  currentUnlockKey: any;
  currentUnlockKeySubscription: Subscription;
  public router: Router
    // public route: ActivatedRoute,

  constructor(
    public dlgRef: NbDialogRef<SecretInputComponent>,
  ) {
  }
  ngOnInit(){
    // localStorage.removeItem("orgKey")

    this.currentUnlockKeySubscription = this.gds.currentOrgObservable.subscribe(message => this.currentUnlockKey = message)
  }

  close() {
    this.dlgRef.close();
    // this.gds.navigateToOrganisation(organisation);
  }

  exit(){
    console.error("Exiting ...")
    this.dlgRef.close()
    this.gds.router.navigateByUrl("effortless/organisations");
  }


  unlock(unlockKey) {
    let key = this.org.Name+"-orgKey"
    localStorage.setItem(key, JSON.stringify(unlockKey))
    this.gds.broadcastCurrentUnlockKey(unlockKey)
    this.close();
  }

  ngOnDestroy(){
    this.currentUnlockKeySubscription.unsubscribe();
  }


}
