import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NbToastrService } from "@nebular/theme";
import { GDS } from "./gds.service";

@Injectable()
export class OrgService {
  organisations: any;
  organisationUsers: any;

  constructor(
    public gds: GDS,
    public toastr: NbToastrService,
    private router: Router
  ) {
    gds.organisationAdminReady$.subscribe((ready) => {
      if (!ready || !this.gds.smqUser) {
        console.error("NOT READY to load static data.  Should reload again");
        return;
      } else {
        console.error("STATIC DATA READY TO LOAD....");
      }

      this.loadStaticData();
      this.loadAllOrganisations();
      this.loadOrganisationUsers();
    });

    // gds.sharedDataReady$.subscribe(read => {
    //   this.loadSharedData();
    // })
  }
  async loadStaticData() {
    if (!this.gds.triggerTypes) {
      var payload = this.gds.createPayload();
      var triggerTypesPayload = await this.gds.smqUser.GetTriggerTypes(payload);
      if (this.gds.hasNoErrors(triggerTypesPayload)) {
        this.gds.triggerTypes = triggerTypesPayload.TriggerTypes;
        this.gds.triggerTypesById = this.gds.triggerTypes.reduce(
          (map, triggerType) => {
            map[triggerType.TriggerTypeId] = triggerType;
            return map;
          },
          {}
        );
      }
      var organisationTypesPayload =
        await this.gds.smqUser.GetOrganisationTypes(payload);
      if (this.gds.hasNoErrors(organisationTypesPayload)) {
        this.gds.organisationTypes = organisationTypesPayload.OrganisationTypes;
        this.gds.organisationTypesById = this.gds.organisationTypes.reduce(
          (map, orgType) => {
            map[orgType.OrganisationTypeId] = orgType;
            return map;
          },
          {}
        );
      }
    }
  }

  private async loadAllOrganisations() {
    if (!this.organisations) {
      var payload = this.gds.createPayload();
      var orgPayload = await (
        this.gds.smqAdmin || this.gds.smqUser
      ).GetOrganisations(payload);
      if (this.gds.hasNoErrors(payload)) {
        if (!orgPayload.Organisations || !orgPayload.Organisations.length) {
          alert("Error getting organisation for login");
        } else {
          this.organisations = orgPayload.Organisations.sort((a, b) => {
            if (a.AutoStart && !b.AutoStart) {
              return -1;
            } else if (!a.AutoStart && b.AutoStart) {
              return 1;
            } else {
              return a.Name > b.Name ? 1 : -1;
            }
          });
          console.error("GOT ALL ORGANISATIONS FROM DB: ", this.organisations);
          if (!this.organisations) alert("Can't load organisations.");
          else
            this.organisations.forEach((organisation) => {
              console.error("ADDING NEW ORGANISATION: ", organisation);
              organisation.$badges = organisation.$badges || [];
              this.gds.lastOrgId =
                this.gds.lastOrgId || organisation.OrganisationId;
              this.gds.orgsById[organisation.OrganisationId] = organisation;
            });
          this.gds.isGuestConnected = true;
          this.gds.readiness$.next(this);
        }
      }
    }
  }

  async addOrguser(organisationUser) {
    if (organisationUser) {
      var payload = this.gds.createPayload();
      payload.OrganisationId = organisationUser.Organization;
      payload.AppUser = organisationUser;
      payload.Roles = ["OrganisationAdmin"];
      var appUserReply = await this.gds.smqUser.AddAppUser(payload);
      if (this.gds.hasNoErrors(appUserReply)) {
        payload = this.gds.createPayload();
        payload.OrganisationId = organisationUser.Organization;
        organisationUser.AppUser = appUserReply.AppUser.AppUserId;
        payload.OrganisationUser = organisationUser;
        var reply = await this.gds.smqUser.AddOrganisationUser(payload);
        if (this.gds.hasNoErrors(reply)) {
          this.organisationUsers.push(reply.OrganisationUser);
        }
      }
    }
  }

  async deleteOrguser(organisationUser) {
    if (organisationUser) {
      if (
        confirm(
          `Are you sure you want to delete '${
            organisationUser.AppUserEmailAddress || "noemail@domain.com"
          }''`
        )
      ) {
        var payload = this.gds.createPayload();
        payload.OrganisationUser = organisationUser;
        var reply = await this.gds.smqUser.DeleteOrganisationUser(payload);
        if (this.gds.hasNoErrors(reply)) {
          var index = this.organisationUsers.indexOf(organisationUser);
          if (index >= 0) this.organisationUsers.splice(index, 1);
        }
      }
    }
  }

  // async loadSharedData(organisation = null) {
  //   console.error('LOADING STATIC DATA: ', this.gds.smqUser);
  //   if (!this.gds.smqUser) {
  //     this.router.navigateByUrl('/auth/login');
  //   }
  //   else if (!organisation) console.error(' ------     Proxy server still starting...'); //  || !organisation.$proxyDMQueue
  //   else {
  //     let payload = this.gds.createPayload();
  //     payload.LMSCurrentTermId = organisation.LMSCurrentTermId;
  //     payload.OrganisationId = organisation.OrganisationId;
  //     console.error('TRIGGER TYPES: ', this.gds.triggerTypes);
  //     var badgeMapPayload = await organisation.localUser.LoadBadgeMap(payload);
  //     console.error('GOT BADGE MAP PAYLOAD: ', badgeMapPayload);
  //     if (badgeMapPayload.ErrorMessage) {
  //       this.toastr.show(badgeMapPayload.ErrorMessage, 'Error', { status: 'danger' });
  //     } else {
  //       organisation.$courseBadges = badgeMapPayload.CourseBadges.sort((a, b) => {
  //         if (a.Name > b.Name) return 1;
  //         else if (b.Name > a.Name) return -1;
  //         else return 0;
  //       });

  //       organisation.$courses = badgeMapPayload.LMSAllCourses;
  //       organisation.$coursesById = {};
  //       organisation.$badgedCourses = organisation.$courses.filter(course => course.CourseBadges && course.CourseBadges.length);
  //       organisation.$unbadgedCourses = organisation.$courses.filter(course => !course.CourseBadges || !course.CourseBadges.length);
  //       organisation.$courses.forEach(course => {
  //         organisation.$coursesById[course.LMSCourseId] = course;
  //       });

  //       organisation.$badges = badgeMapPayload.Badges;
  //       organisation.$badgesById = {};
  //       organisation.$badges.forEach(badge => {
  //         organisation.$badgesById[badge.BadgeIdentifier] = badge;
  //       });

  //       organisation.terms = badgeMapPayload.LMSTerms;

  //       this.toastr.show('Done', 'Shared data loaded');

  //       console.error('GOT LMS DETAILS', organisation.$courseBadges, organisation.$courses, organisation.$coursesById, organisation.$badges, organisation.$badgesById);
  //     }
  //   }
  // }

  private async loadOrganisationUsers() {
    var payload = this.gds.createPayload();
    var user = this.gds.smqAdmin || this.gds.smqUser;
    if (user && (!this.organisationUsers || !this.organisationUsers.length)) {
      var orgPayload = await user.GetOrganisationUsers(payload);
      if (
        !orgPayload.OrganisationUsers ||
        !orgPayload.OrganisationUsers.length
      ) {
        alert("Error getting app users." + orgPayload.ErrorMessage);
      } else {
        this.organisationUsers = orgPayload.OrganisationUsers;
        console.error("ORGANISATION USERS: ", this.organisationUsers);
      }
    }
  }
}
