/*
THIS FILE IS DERIVED - CHANGES WILL BE OVERWRITTEN (derived)
*/
import { EapiEndpointBase } from './eapi-endpoint-base';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, share } from 'rxjs/operators';
import { GDS } from '../../gds.service';

export class DataEndpointBase extends EapiEndpointBase {

    constructor(public gds: GDS) {
        super(gds)
    }






    // HANDLERS FOR: OrganisationUser
    public organisationuser: any = {};
    public organisationusers: any[] = [];
    public organisationusersById: any = {};
    public organisationuser$: BehaviorSubject<any[]> = new BehaviorSubject(null);
    public organisationusers$: BehaviorSubject<any[]> = new BehaviorSubject(null);

    public onOrganisationUsersChange(): Observable<any> {
        return this.organisationusers$
            .pipe(
                filter(value => !!value),
                share(),
            );
    }

    public onOrganisationUserChange(): Observable<any> {
        return this.organisationuser$
            .pipe(
                filter(value => !!value),
                share(),
            );
    }

    public async reloadOrganisationUsers(smqUser: any = null, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'OrganisationUserId', 'organisationusers', 'OrganisationUsers', '', sortField, true, behaviorSubject);
    }

    public async reloadOrganisationUsersWhere(smqUser: any = null, airtableWhere : string, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'OrganisationUserId', 'organisationusers', 'OrganisationUsers', airtableWhere, sortField, true, behaviorSubject);
    }

    public async reloadOrganisationUserWhere(smqUser: any = null, airtableWhere : string, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'OrganisationUserId', 'organisationuser', 'OrganisationUsers', airtableWhere, sortField, false, behaviorSubject);
    }

    public organisationusersSort(organisationuserA: any, organisationuserB: any) {
        return EapiEndpointBase.defaultSort(organisationuserA, organisationuserB);
    } 





    // HANDLERS FOR: LogEntry
    public logentry: any = {};
    public logentries: any[] = [];
    public logentriesById: any = {};
    public logentry$: BehaviorSubject<any[]> = new BehaviorSubject(null);
    public logentries$: BehaviorSubject<any[]> = new BehaviorSubject(null);

    public onLogEntriesChange(): Observable<any> {
        return this.logentries$
            .pipe(
                filter(value => !!value),
                share(),
            );
    }

    public onLogEntryChange(): Observable<any> {
        return this.logentry$
            .pipe(
                filter(value => !!value),
                share(),
            );
    }

    public async reloadLogEntries(smqUser: any = null, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'LogEntryId', 'logentries', 'LogEntries', '', sortField, true, behaviorSubject);
    }

    public async reloadLogEntriesWhere(smqUser: any = null, airtableWhere : string, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'LogEntryId', 'logentries', 'LogEntries', airtableWhere, sortField, true, behaviorSubject);
    }

    public async reloadLogEntryWhere(smqUser: any = null, airtableWhere : string, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'LogEntryId', 'logentry', 'LogEntries', airtableWhere, sortField, false, behaviorSubject);
    }

    public logentriesSort(logentryA: any, logentryB: any) {
        return EapiEndpointBase.defaultSort(logentryA, logentryB);
    } 





    // HANDLERS FOR: IssuedBadge
    public issuedbadge: any = {};
    public issuedbadges: any[] = [];
    public issuedbadgesById: any = {};
    public issuedbadge$: BehaviorSubject<any[]> = new BehaviorSubject(null);
    public issuedbadges$: BehaviorSubject<any[]> = new BehaviorSubject(null);

    public onIssuedBadgesChange(): Observable<any> {
        return this.issuedbadges$
            .pipe(
                filter(value => !!value),
                share(),
            );
    }

    public onIssuedBadgeChange(): Observable<any> {
        return this.issuedbadge$
            .pipe(
                filter(value => !!value),
                share(),
            );
    }

    public async reloadIssuedBadges(smqUser: any = null, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'IssuedBadgeId', 'issuedbadges', 'IssuedBadges', '', sortField, true, behaviorSubject);
    }

    public async reloadIssuedBadgesWhere(smqUser: any = null, airtableWhere : string, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'IssuedBadgeId', 'issuedbadges', 'IssuedBadges', airtableWhere, sortField, true, behaviorSubject);
    }

    public async reloadIssuedBadgeWhere(smqUser: any = null, airtableWhere : string, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'IssuedBadgeId', 'issuedbadge', 'IssuedBadges', airtableWhere, sortField, false, behaviorSubject);
    }

    public issuedbadgesSort(issuedbadgeA: any, issuedbadgeB: any) {
        return EapiEndpointBase.defaultSort(issuedbadgeA, issuedbadgeB);
    } 





    // HANDLERS FOR: Term
    public term: any = {};
    public terms: any[] = [];
    public termsById: any = {};
    public term$: BehaviorSubject<any[]> = new BehaviorSubject(null);
    public terms$: BehaviorSubject<any[]> = new BehaviorSubject(null);

    public onTermsChange(): Observable<any> {
        return this.terms$
            .pipe(
                filter(value => !!value),
                share(),
            );
    }

    public onTermChange(): Observable<any> {
        return this.term$
            .pipe(
                filter(value => !!value),
                share(),
            );
    }

    public async reloadTerms(smqUser: any = null, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'TermId', 'terms', 'Terms', '', sortField, true, behaviorSubject);
    }

    public async reloadTermsWhere(smqUser: any = null, airtableWhere : string, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'TermId', 'terms', 'Terms', airtableWhere, sortField, true, behaviorSubject);
    }

    public async reloadTermWhere(smqUser: any = null, airtableWhere : string, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'TermId', 'term', 'Terms', airtableWhere, sortField, false, behaviorSubject);
    }

    public termsSort(termA: any, termB: any) {
        return EapiEndpointBase.defaultSort(termA, termB);
    } 





    // HANDLERS FOR: OpenIssue
    public openissue: any = {};
    public openissues: any[] = [];
    public openissuesById: any = {};
    public openissue$: BehaviorSubject<any[]> = new BehaviorSubject(null);
    public openissues$: BehaviorSubject<any[]> = new BehaviorSubject(null);

    public onOpenIssuesChange(): Observable<any> {
        return this.openissues$
            .pipe(
                filter(value => !!value),
                share(),
            );
    }

    public onOpenIssueChange(): Observable<any> {
        return this.openissue$
            .pipe(
                filter(value => !!value),
                share(),
            );
    }

    public async reloadOpenIssues(smqUser: any = null, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'OpenIssueId', 'openissues', 'OpenIssues', '', sortField, true, behaviorSubject);
    }

    public async reloadOpenIssuesWhere(smqUser: any = null, airtableWhere : string, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'OpenIssueId', 'openissues', 'OpenIssues', airtableWhere, sortField, true, behaviorSubject);
    }

    public async reloadOpenIssueWhere(smqUser: any = null, airtableWhere : string, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'OpenIssueId', 'openissue', 'OpenIssues', airtableWhere, sortField, false, behaviorSubject);
    }

    public openissuesSort(openissueA: any, openissueB: any) {
        return EapiEndpointBase.defaultSort(openissueA, openissueB);
    } 





    // HANDLERS FOR: BadgeApplication
    public badgeapplication: any = {};
    public badgeapplications: any[] = [];
    public badgeapplicationsById: any = {};
    public badgeapplication$: BehaviorSubject<any[]> = new BehaviorSubject(null);
    public badgeapplications$: BehaviorSubject<any[]> = new BehaviorSubject(null);

    public onBadgeApplicationsChange(): Observable<any> {
        return this.badgeapplications$
            .pipe(
                filter(value => !!value),
                share(),
            );
    }

    public onBadgeApplicationChange(): Observable<any> {
        return this.badgeapplication$
            .pipe(
                filter(value => !!value),
                share(),
            );
    }

    public async reloadBadgeApplications(smqUser: any = null, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'BadgeApplicationId', 'badgeapplications', 'BadgeApplications', '', sortField, true, behaviorSubject);
    }

    public async reloadBadgeApplicationsWhere(smqUser: any = null, airtableWhere : string, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'BadgeApplicationId', 'badgeapplications', 'BadgeApplications', airtableWhere, sortField, true, behaviorSubject);
    }

    public async reloadBadgeApplicationWhere(smqUser: any = null, airtableWhere : string, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'BadgeApplicationId', 'badgeapplication', 'BadgeApplications', airtableWhere, sortField, false, behaviorSubject);
    }

    public badgeapplicationsSort(badgeapplicationA: any, badgeapplicationB: any) {
        return EapiEndpointBase.defaultSort(badgeapplicationA, badgeapplicationB);
    } 





    // HANDLERS FOR: APIKey
    public apikey: any = {};
    public apikeys: any[] = [];
    public apikeysById: any = {};
    public apikey$: BehaviorSubject<any[]> = new BehaviorSubject(null);
    public apikeys$: BehaviorSubject<any[]> = new BehaviorSubject(null);

    public onAPIKeysChange(): Observable<any> {
        return this.apikeys$
            .pipe(
                filter(value => !!value),
                share(),
            );
    }

    public onAPIKeyChange(): Observable<any> {
        return this.apikey$
            .pipe(
                filter(value => !!value),
                share(),
            );
    }

    public async reloadAPIKeys(smqUser: any = null, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'APIKeyId', 'apikeys', 'APIKeys', '', sortField, true, behaviorSubject);
    }

    public async reloadAPIKeysWhere(smqUser: any = null, airtableWhere : string, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'APIKeyId', 'apikeys', 'APIKeys', airtableWhere, sortField, true, behaviorSubject);
    }

    public async reloadAPIKeyWhere(smqUser: any = null, airtableWhere : string, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'APIKeyId', 'apikey', 'APIKeys', airtableWhere, sortField, false, behaviorSubject);
    }

    public apikeysSort(apikeyA: any, apikeyB: any) {
        return EapiEndpointBase.defaultSort(apikeyA, apikeyB);
    } 





    // HANDLERS FOR: Badge
    public badge: any = {};
    public badges: any[] = [];
    public badgesById: any = {};
    public badge$: BehaviorSubject<any[]> = new BehaviorSubject(null);
    public badges$: BehaviorSubject<any[]> = new BehaviorSubject(null);

    public onBadgesChange(): Observable<any> {
        return this.badges$
            .pipe(
                filter(value => !!value),
                share(),
            );
    }

    public onBadgeChange(): Observable<any> {
        return this.badge$
            .pipe(
                filter(value => !!value),
                share(),
            );
    }

    public async reloadBadges(smqUser: any = null, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'BadgeId', 'badges', 'Badges', '', sortField, true, behaviorSubject);
    }

    public async reloadBadgesWhere(smqUser: any = null, airtableWhere : string, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'BadgeId', 'badges', 'Badges', airtableWhere, sortField, true, behaviorSubject);
    }

    public async reloadBadgeWhere(smqUser: any = null, airtableWhere : string, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'BadgeId', 'badge', 'Badges', airtableWhere, sortField, false, behaviorSubject);
    }

    public badgesSort(badgeA: any, badgeB: any) {
        return EapiEndpointBase.defaultSort(badgeA, badgeB);
    } 





    // HANDLERS FOR: Course
    public course: any = {};
    public courses: any[] = [];
    public coursesById: any = {};
    public course$: BehaviorSubject<any[]> = new BehaviorSubject(null);
    public courses$: BehaviorSubject<any[]> = new BehaviorSubject(null);

    public onCoursesChange(): Observable<any> {
        return this.courses$
            .pipe(
                filter(value => !!value),
                share(),
            );
    }

    public onCourseChange(): Observable<any> {
        return this.course$
            .pipe(
                filter(value => !!value),
                share(),
            );
    }

    public async reloadCourses(smqUser: any = null, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'CourseId', 'courses', 'Courses', '', sortField, true, behaviorSubject);
    }

    public async reloadCoursesWhere(smqUser: any = null, airtableWhere : string, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'CourseId', 'courses', 'Courses', airtableWhere, sortField, true, behaviorSubject);
    }

    public async reloadCourseWhere(smqUser: any = null, airtableWhere : string, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'CourseId', 'course', 'Courses', airtableWhere, sortField, false, behaviorSubject);
    }

    public coursesSort(courseA: any, courseB: any) {
        return EapiEndpointBase.defaultSort(courseA, courseB);
    } 





    // HANDLERS FOR: Organisation
    public organisation: any = {};
    public organisations: any[] = [];
    public organisationsById: any = {};
    public organisation$: BehaviorSubject<any[]> = new BehaviorSubject(null);
    public organisations$: BehaviorSubject<any[]> = new BehaviorSubject(null);

    public onOrganisationsChange(): Observable<any> {
        return this.organisations$
            .pipe(
                filter(value => !!value),
                share(),
            );
    }

    public onOrganisationChange(): Observable<any> {
        return this.organisation$
            .pipe(
                filter(value => !!value),
                share(),
            );
    }

    public async reloadOrganisations(smqUser: any = null, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'OrganisationId', 'organisations', 'Organisations', '', sortField, true, behaviorSubject);
    }

    public async reloadOrganisationsWhere(smqUser: any = null, airtableWhere : string, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'OrganisationId', 'organisations', 'Organisations', airtableWhere, sortField, true, behaviorSubject);
    }

    public async reloadOrganisationWhere(smqUser: any = null, airtableWhere : string, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'OrganisationId', 'organisation', 'Organisations', airtableWhere, sortField, false, behaviorSubject);
    }

    public organisationsSort(organisationA: any, organisationB: any) {
        return EapiEndpointBase.defaultSort(organisationA, organisationB);
    } 





    // HANDLERS FOR: AppUser
    public appuser: any = {};
    public appusers: any[] = [];
    public appusersById: any = {};
    public appuser$: BehaviorSubject<any[]> = new BehaviorSubject(null);
    public appusers$: BehaviorSubject<any[]> = new BehaviorSubject(null);

    public onAppUsersChange(): Observable<any> {
        return this.appusers$
            .pipe(
                filter(value => !!value),
                share(),
            );
    }

    public onAppUserChange(): Observable<any> {
        return this.appuser$
            .pipe(
                filter(value => !!value),
                share(),
            );
    }

    public async reloadAppUsers(smqUser: any = null, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'AppUserId', 'appusers', 'AppUsers', '', sortField, true, behaviorSubject);
    }

    public async reloadAppUsersWhere(smqUser: any = null, airtableWhere : string, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'AppUserId', 'appusers', 'AppUsers', airtableWhere, sortField, true, behaviorSubject);
    }

    public async reloadAppUserWhere(smqUser: any = null, airtableWhere : string, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'AppUserId', 'appuser', 'AppUsers', airtableWhere, sortField, false, behaviorSubject);
    }

    public appusersSort(appuserA: any, appuserB: any) {
        return EapiEndpointBase.defaultSort(appuserA, appuserB);
    } 





    // HANDLERS FOR: BadgeCandidate
    public badgecandidate: any = {};
    public badgecandidates: any[] = [];
    public badgecandidatesById: any = {};
    public badgecandidate$: BehaviorSubject<any[]> = new BehaviorSubject(null);
    public badgecandidates$: BehaviorSubject<any[]> = new BehaviorSubject(null);

    public onBadgeCandidatesChange(): Observable<any> {
        return this.badgecandidates$
            .pipe(
                filter(value => !!value),
                share(),
            );
    }

    public onBadgeCandidateChange(): Observable<any> {
        return this.badgecandidate$
            .pipe(
                filter(value => !!value),
                share(),
            );
    }

    public async reloadBadgeCandidates(smqUser: any = null, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'BadgeCandidateId', 'badgecandidates', 'BadgeCandidates', '', sortField, true, behaviorSubject);
    }

    public async reloadBadgeCandidatesWhere(smqUser: any = null, airtableWhere : string, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'BadgeCandidateId', 'badgecandidates', 'BadgeCandidates', airtableWhere, sortField, true, behaviorSubject);
    }

    public async reloadBadgeCandidateWhere(smqUser: any = null, airtableWhere : string, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'BadgeCandidateId', 'badgecandidate', 'BadgeCandidates', airtableWhere, sortField, false, behaviorSubject);
    }

    public badgecandidatesSort(badgecandidateA: any, badgecandidateB: any) {
        return EapiEndpointBase.defaultSort(badgecandidateA, badgecandidateB);
    } 





    // HANDLERS FOR: SecurityAssessmentQuestion
    public securityassessmentquestion: any = {};
    public securityassessmentquestions: any[] = [];
    public securityassessmentquestionsById: any = {};
    public securityassessmentquestion$: BehaviorSubject<any[]> = new BehaviorSubject(null);
    public securityassessmentquestions$: BehaviorSubject<any[]> = new BehaviorSubject(null);

    public onSecurityAssessmentQuestionsChange(): Observable<any> {
        return this.securityassessmentquestions$
            .pipe(
                filter(value => !!value),
                share(),
            );
    }

    public onSecurityAssessmentQuestionChange(): Observable<any> {
        return this.securityassessmentquestion$
            .pipe(
                filter(value => !!value),
                share(),
            );
    }

    public async reloadSecurityAssessmentQuestions(smqUser: any = null, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'SecurityAssessmentQuestionId', 'securityassessmentquestions', 'SecurityAssessmentQuestions', '', sortField, true, behaviorSubject);
    }

    public async reloadSecurityAssessmentQuestionsWhere(smqUser: any = null, airtableWhere : string, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'SecurityAssessmentQuestionId', 'securityassessmentquestions', 'SecurityAssessmentQuestions', airtableWhere, sortField, true, behaviorSubject);
    }

    public async reloadSecurityAssessmentQuestionWhere(smqUser: any = null, airtableWhere : string, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'SecurityAssessmentQuestionId', 'securityassessmentquestion', 'SecurityAssessmentQuestions', airtableWhere, sortField, false, behaviorSubject);
    }

    public securityassessmentquestionsSort(securityassessmentquestionA: any, securityassessmentquestionB: any) {
        return EapiEndpointBase.defaultSort(securityassessmentquestionA, securityassessmentquestionB);
    } 





    // HANDLERS FOR: CourseBadge
    public coursebadge: any = {};
    public coursebadges: any[] = [];
    public coursebadgesById: any = {};
    public coursebadge$: BehaviorSubject<any[]> = new BehaviorSubject(null);
    public coursebadges$: BehaviorSubject<any[]> = new BehaviorSubject(null);

    public onCourseBadgesChange(): Observable<any> {
        return this.coursebadges$
            .pipe(
                filter(value => !!value),
                share(),
            );
    }

    public onCourseBadgeChange(): Observable<any> {
        return this.coursebadge$
            .pipe(
                filter(value => !!value),
                share(),
            );
    }

    public async reloadCourseBadges(smqUser: any = null, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'CourseBadgeId', 'coursebadges', 'CourseBadges', '', sortField, true, behaviorSubject);
    }

    public async reloadCourseBadgesWhere(smqUser: any = null, airtableWhere : string, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'CourseBadgeId', 'coursebadges', 'CourseBadges', airtableWhere, sortField, true, behaviorSubject);
    }

    public async reloadCourseBadgeWhere(smqUser: any = null, airtableWhere : string, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'CourseBadgeId', 'coursebadge', 'CourseBadges', airtableWhere, sortField, false, behaviorSubject);
    }

    public coursebadgesSort(coursebadgeA: any, coursebadgeB: any) {
        return EapiEndpointBase.defaultSort(coursebadgeA, coursebadgeB);
    } 





    // HANDLERS FOR: OrganisationType
    public organisationtype: any = {};
    public organisationtypes: any[] = [];
    public organisationtypesById: any = {};
    public organisationtype$: BehaviorSubject<any[]> = new BehaviorSubject(null);
    public organisationtypes$: BehaviorSubject<any[]> = new BehaviorSubject(null);

    public onOrganisationTypesChange(): Observable<any> {
        return this.organisationtypes$
            .pipe(
                filter(value => !!value),
                share(),
            );
    }

    public onOrganisationTypeChange(): Observable<any> {
        return this.organisationtype$
            .pipe(
                filter(value => !!value),
                share(),
            );
    }

    public async reloadOrganisationTypes(smqUser: any = null, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'OrganisationTypeId', 'organisationtypes', 'OrganisationTypes', '', sortField, true, behaviorSubject);
    }

    public async reloadOrganisationTypesWhere(smqUser: any = null, airtableWhere : string, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'OrganisationTypeId', 'organisationtypes', 'OrganisationTypes', airtableWhere, sortField, true, behaviorSubject);
    }

    public async reloadOrganisationTypeWhere(smqUser: any = null, airtableWhere : string, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'OrganisationTypeId', 'organisationtype', 'OrganisationTypes', airtableWhere, sortField, false, behaviorSubject);
    }

    public organisationtypesSort(organisationtypeA: any, organisationtypeB: any) {
        return EapiEndpointBase.defaultSort(organisationtypeA, organisationtypeB);
    } 





    // HANDLERS FOR: TriggerType
    public triggertype: any = {};
    public triggertypes: any[] = [];
    public triggertypesById: any = {};
    public triggertype$: BehaviorSubject<any[]> = new BehaviorSubject(null);
    public triggertypes$: BehaviorSubject<any[]> = new BehaviorSubject(null);

    public onTriggerTypesChange(): Observable<any> {
        return this.triggertypes$
            .pipe(
                filter(value => !!value),
                share(),
            );
    }

    public onTriggerTypeChange(): Observable<any> {
        return this.triggertype$
            .pipe(
                filter(value => !!value),
                share(),
            );
    }

    public async reloadTriggerTypes(smqUser: any = null, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'TriggerTypeId', 'triggertypes', 'TriggerTypes', '', sortField, true, behaviorSubject);
    }

    public async reloadTriggerTypesWhere(smqUser: any = null, airtableWhere : string, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'TriggerTypeId', 'triggertypes', 'TriggerTypes', airtableWhere, sortField, true, behaviorSubject);
    }

    public async reloadTriggerTypeWhere(smqUser: any = null, airtableWhere : string, sortField : string = '', behaviorSubject?: BehaviorSubject<any>) {
        return this.doReload(smqUser, 'TriggerTypeId', 'triggertype', 'TriggerTypes', airtableWhere, sortField, false, behaviorSubject);
    }

    public triggertypesSort(triggertypeA: any, triggertypeB: any) {
        return EapiEndpointBase.defaultSort(triggertypeA, triggertypeB);
    } 

}
