import { Injectable } from '@angular/core';

@Injectable()

export class Globals {
    public selectedFacility: any = {};
    public selectedClient: any = {};

    constructor() {

    }
}
