import { Directive, OnDestroy, Output, Input } from "@angular/core";
import { NgModel } from "@angular/forms";
import { EventEmitter } from "events";
import { Subscription } from "rxjs";
import { skip, distinctUntilChanged, debounceTime } from "rxjs/operators";


@Directive({
    selector: '[ngModelChangeDebounced]',
  })
  export class NgModelChangeDebouncedDirective implements OnDestroy {
    @Output()
    ngModelChangeDebounced = new EventEmitter();
    @Input()
    ngModelChangeDebounceTime = 500; // optional, 500 default
  
    subscription: Subscription;
    ngOnDestroy() {
      this.subscription.unsubscribe();
    }
  
    constructor(private ngModel: NgModel) {
      this.subscription = this.ngModel.control.valueChanges.pipe(
        skip(1), // skip initial value
        distinctUntilChanged(),
        debounceTime(this.ngModelChangeDebounceTime)
      ).subscribe((value) => this.ngModelChangeDebounced.emit(value));
    }
  }