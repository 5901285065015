import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <div class="created-by">Created with ♥ by <b><a href="https://effortlessapi.com" target="_blank">EffortlessAPI</a></b> <div style="float: right;font-size: 0.7em; color: AAA;">{{version}}</div></div>
  `,
})
export class FooterComponent {
  public version = environment.version;
}
