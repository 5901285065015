import { Component, OnInit, Input, Output } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { EventEmitter } from 'events';
import { GDS } from '../../services/gds.service';

@Component({
  selector: 'ngx-pick-orgnisation',
  templateUrl: './pick-orgnisation.component.html',
  styleUrls: ['./pick-orgnisation.component.scss']
})


export class PickOrgnisationComponent {
  @Input() lastOrgId : any;
  @Input() gds : any;
  @Input() orgSvc : any;

  isAddNewOrgButtonClicked: boolean = false;



  constructor(public dlgRef: NbDialogRef<PickOrgnisationComponent>) {

  }

  close(organisation) {
    this.dlgRef.close();
    this.gds.navigateToOrganisation(organisation);
  }

  openAddNewOrgSection(){
    this.isAddNewOrgButtonClicked = !this.isAddNewOrgButtonClicked;
  }
}
