import { GDS } from '../../gds.service';
import { Observable, BehaviorSubject } from 'rxjs';

export class EapiEndpointBase {
    lastReload: {};
    constructor(public gds: GDS) {
 
    }

    public static defaultSort(defaultA: any, defaultB: any): number {
        let objectResult = EapiEndpointBase.objectSort(defaultA, defaultB);

        if ((objectResult == 0) && defaultA && defaultA.Name) {
            if (defaultA.Name > defaultB.Name) return 1;
            else if (defaultB.Name > defaultA.Name) return -1;
        }

        return 0;
    }

    public static objectSort(defaultA: any, defaultB: any): number {
        if (defaultA && !defaultB) return 1;
        else if (defaultB && !defaultA) return -1;
        return 0;
    }

    public static sortBy(items: any, sortField: string): any {
        return items.sort(function (a, b) {
            var objectSort = EapiEndpointBase.objectSort(a, b);
            if (objectSort == 0 && a) {
                if (a[sortField] > b[sortField]) return 1;
                else if (b[sortField] > a[sortField]) return -1;
            }
            return 0;
        })
    }
    

    //---- Still Testing --------
    async get(smqUser: any, entityNamePlural: string, airtableWhere: string = "", view: string = "") {
        let methodName = 'Get' + entityNamePlural;
        if (!smqUser[methodName]) this.gds.toastr.danger(`ERROR smqUser ${methodName} not found`);
        else {
          var payload = (typeof smqUser.createPayload == 'function') ? smqUser.createPayload() :this.gds.createPayload();
          payload.AirtableWhere = airtableWhere;
          payload.View = view;
          payload.MaxPages = 99;
          let reply = await smqUser[methodName](payload);
          if (this.gds.hasNoErrors(reply)) {
            return reply[entityNamePlural];
          }
          else {
             this.gds.toastr.danger(`ERROR: smqUser.${methodName}() -> ${reply.ErrroMessage}`);
            return null;
          }
        }
    } 
    async getCached(smqUser: any, entityNamePlural: string, airtableWhere: string = "", view: string = "") {
        var today = new Date();
        var yesterday = new Date().setMinutes(new Date().getMinutes() - 5);
        var key = '_static_' + entityNamePlural;
        var lastUpdatedKey = key + '_lastupdated';
        var valueString = localStorage.getItem(key);
        var lastUpdated = localStorage.getItem(lastUpdatedKey);
        var lastUpdatedDate = lastUpdated ? Date.parse(JSON.parse(lastUpdated)) : yesterday;
    
        if (valueString) {
          if (lastUpdatedDate <= yesterday) {
            setTimeout(async () => {
              valueString = await this.updateCachedKey(smqUser, entityNamePlural, airtableWhere, view, valueString, key, lastUpdatedKey, today);
            }, 0)
          }
        } else {
          valueString = await this.updateCachedKey(smqUser, entityNamePlural, airtableWhere, view, valueString, key, lastUpdatedKey, today);
        }
    
        return JSON.parse(valueString);
      }
    
      private async updateCachedKey(smqUser: any, entityNamePlural: string, airtableWhere: string, view: string, valueString: string, key: string, lastUpdatedKey: string, today: Date) {
        let value = await this.get(smqUser, entityNamePlural, airtableWhere, view);
        valueString = JSON.stringify(value);
        localStorage.setItem(key, valueString);
        localStorage.setItem(lastUpdatedKey, JSON.stringify(today));
        return valueString;
      }
      //-------

    public async doReload(smqUser: any, idName: string, lowerName: string, upperName: string, airtableWhere: string, sortField: string = '', isPlural: boolean = true, behaviorSubject? : BehaviorSubject<any>) : Promise<any> {
        return new Promise((accept, reject) => {
            let self = this;

            this.lastReload = this.lastReload || {};
            let whereKey = idName + '_where' + isPlural;
            if (self.lastReload[whereKey] && self.lastReload[whereKey] != airtableWhere)  {
                if (isPlural) {
                    self[lowerName] = [];
                    self[lowerName + '$'].next([]);
                } else {
                    self[lowerName] = {};
                    self[lowerName + '$'].next({});
                }
            }
                
            this.lastReload[whereKey] = airtableWhere;

            if (!smqUser) smqUser = self.gds.smqUser;
            let payload = smqUser.createPayload ? smqUser.createPayload() : self.gds.createPayload();
            payload.AirtableWhere = airtableWhere;
            if (!smqUser || !smqUser['Get' + upperName]) {
                console.error('SMQ User does not contain: ' + 'Get' + upperName + '();', smqUser, payload);
            } else {
                var lastReloadRequested = this.lastReload[idName] = new Date().getTime();
                console.error('RELOADING WHERE KEY: ' + whereKey, lastReloadRequested);
                if (whereKey == 'TableColumnId_where') {
                    console.error('RELOADING COLUMNS!!!!!');
                }
                smqUser['Get' + upperName](payload)
                    .then(reply => {
                        console.log('GOT REPLY: ', whereKey, reply);
                        let items = reply[upperName];

                        if (this.lastReload[idName] != lastReloadRequested) {
                            console.error('DATA RECEIVED - discarding because lastReloadRequested date changed', whereKey, lastReloadRequested, this.lastReload[idName],  items);
                            return;
                        }
                        else {
                            self[lowerName + 'ById'] = {}
                            let items = reply[upperName];
                            if (items) {
                                if (sortField) {
                                    items = EapiEndpointBase.sortBy(items, sortField);
                                } else items = items.sort(self[lowerName + 'Sort']);
                                items.forEach(item => {
                                    self[lowerName + 'ById'][item[idName]] = item;
                                })
                            }
                            var nextValue = isPlural ? items : items[0];
                            self[lowerName] = nextValue;
                            
                            if (behaviorSubject) behaviorSubject.next(nextValue);
                            else self[lowerName + '$'].next(nextValue);
                            accept(nextValue);
                        }
                    }, (error) => {
                        reject(error);
                    });
            }
        });
    }
}