import { Component, Input, OnInit } from '@angular/core';
import { ɵangular_packages_platform_browser_dynamic_platform_browser_dynamic_a } from '@angular/platform-browser-dynamic';
import { NbDialogRef } from '@nebular/theme';
import { environment } from '../../../../environments/environment';
import { GDS } from '../../services/gds.service';

@Component({
  selector: 'ngx-edit-organisation-secrets',
  templateUrl: './edit-organisation-secrets.component.html',
  styleUrls: ['./edit-organisation-secrets.component.scss']
})
export class EditOrganisationSecretsComponent implements OnInit {
  @Input() org : any;
  @Input() gds : any;
  isEditDisabled:any;
  constructor(public dlgRef : NbDialogRef<EditOrganisationSecretsComponent>) { }

  ngOnInit() {
  }

  setDefaults() {
    this.org.LMSAppId = this.org.LMSAppId || '24f40a93-959c-4c53-a123-925889f11abe';
    this.org.AMQPSConnectionString = this.org.AMQPSConnectionString || environment.amqpsRabbitEndpoint;
    this.org.AMQPSUsername = this.org.AMQPSUsername || 'smqPublic';
    this.org.AMQPSPassword = this.org.AMQPSPassword || 'smqPublic';
    this.org.AMQPSVHost = this.org.AMQPSVHost || environment.vhost
    this.org.WSSRabbitEndpoint = environment.wssRabbitEndpoint;
    this.org.LMSCode = this.org.LMSCode || 'BB';
  }
}
