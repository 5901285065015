declare var generateAdminActor: any;
declare var generateOrganisationAdminActor: any;
declare var generateUserActor: any;
declare var generateEmployeeActor: any;

import { Injectable, ɵConsole } from "@angular/core";
import {
  NbDialogService,
  NbMenuService,
  NbToastrService,
} from "@nebular/theme";
import {
  Observable,
  BehaviorSubject,
  Subscription,
  timer,
  combineLatest,
} from "rxjs";
import { Router } from "@angular/router";
import { filter, share, map, catchError, take, tap } from "rxjs/operators";
import { PickOrgnisationComponent } from "../organisations/pick-orgnisation/pick-orgnisation.component";
import { EditOrganisationSecretsComponent } from "../organisations/edit-organisation-secrets/edit-organisation-secrets.component";
import { SecretInputComponent } from "../organisations/secret-input/secret-input.component";
import { ChangePasswordWidgetComponent } from "../widgets/change-password-widget/change-password-widget.component";

interface TSWindow {
  [id: string]: any;
}

@Injectable()
export class GDS {
  organisationAdminReady$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  async checkCourseCode(organisation: any, courseCode: any) {
    console.error("CHECKING COURSE CODE: ", organisation, courseCode);
    var payload = this.createPayload();
    payload.OrganisationId = organisation.OrganisationId;
    payload.CourseCode = courseCode;
    var reply = await this.smqUser.GetLMSDetails(payload);
    console.error("CHECKING COURSE:", reply, organisation, courseCode);
    if (this.hasNoErrors(reply)) {
      return reply.Courses;
    }
  }

  navigateToOrganisation(organisation: any) {
    if (organisation) {
      this.router.navigateByUrl(
        "/effortless/organisations/" + organisation.OrganisationId
      );
    } else this.toastr.danger("Can't navigate to null organisation.");
  }

  closeSecreteInput(dlgSvc: NbDialogService) {}
  openSecretInput(dlgSvc: NbDialogService, organisation: any) {
    dlgSvc
      .open(SecretInputComponent, {
        closeOnBackdropClick: false,
        context: {
          gds: this,
          org: {
            Name: organisation.Name,
            OrganisationId: organisation.OrganisationId,
            AMQPSConnectionString: organisation.AMQPSConnectionString,
            WSSRabbitEndpoint: organisation.WSSRabbitEndpoint,
            AMQPSUsername: organisation.AMQPSUsername,
            AMQPSPassword: organisation.AMQPSPassword,
            AMQPSVHost: organisation.AMQPSVHost,
            OrganisationType: organisation.OrganisationType,
            OrganisationTypeName: organisation.OrganisationTypeName,
          },
        },
      })
      .onClose.pipe(take(1))
      .subscribe((result) => {
        console.error("dialog closed", result);
      });
  }

  changePassword(dlgSvc : NbDialogService) {
    dlgSvc.open(ChangePasswordWidgetComponent, {
      context: {}
    }).onClose.pipe(take(1))
      .subscribe(async (newPassword) => {
        if (newPassword) {
          var payload = this.createPayload();
          payload.NewPassword = newPassword;
          var reply = await this.smqUser.SetMyPassword(payload)
          console.error('CHANGING PASSWORD: ', newPassword);
          if (this.hasNoErrors(reply)) {
            console.error('SET PASSWOD: ', reply);
            this.toastr.show("Password successfully changed")
          }
        }
      });

  }

  editSecrets(dlgSvc: NbDialogService, organisation: any) {
    organisation.isEditing = true;    

    dlgSvc
      .open(EditOrganisationSecretsComponent, {
        context: {
          org: {
            OrganisationId: organisation.OrganisationId,
            AMQPSConnectionString: organisation.AMQPSConnectionString,
            WSSRabbitEndpoint: organisation.WSSRabbitEndpoint,
            AMQPSUsername: organisation.AMQPSUsername,
            AMQPSPassword: organisation.AMQPSPassword,
            AMQPSVHost: organisation.AMQPSVHost,
            OrganisationType: organisation.OrganisationType,
            OrganisationTypeName: organisation.OrganisationTypeName,
          },
          gds: this,
        },
      })
      .onClose.pipe(take(1))
      .subscribe(async (updatedOrg) => {
        organisation.isEditing = false;
        if (updatedOrg) {          
          var payload = this.createPayload();
          delete updatedOrg.$courses;
          delete updatedOrg.$badges;
          payload.Organisation = updatedOrg;
          var reply = await this.smqUser.SaveOrganisationSecrets(payload);
          if (this.hasNoErrors(reply)) {
            console.error("UPDATED SECRETS: ", reply, updatedOrg);
            window.location.href = '/effortless/organisations/' + updatedOrg.OrganisationId;
          }
        }
      });
  }

  goToOrganisations() {
    window.location.href = '/effortless/organsations';
  }

  pickOrganisation(dlgSvc: NbDialogService, orgSvc: any) {
    console.error('USING ORG SERVICE: ', orgSvc);
    dlgSvc
      .open(PickOrgnisationComponent, {
        context: {
          lastOrgId: this.lastOrgId,
          gds: this,
          orgSvc: orgSvc,
        },
      })
      .onClose.pipe(take(1))
      .subscribe((result) => {
        console.error("dialog closed", result);
      });
    console.error("Dialog:", PickOrgnisationComponent);
  }
  stopOrganisation(organisation: any) {
    if (organisation) {
      delete organisation.$badges;
      delete organisation.$badgesById;
      delete organisation.$courses;
      delete organisation.$unbadgedCourses;
      delete organisation.$coursesById;
      delete organisation.$badgemaps;
      delete organisation.$badgemapsByid;
      delete organisation.$proxyDMQueue;
      if (organisation.localUser) {
        organisation.localUser.disconnect();
        delete organisation.localUser;
      }
      organisation.$badges = [];
    }
  }
  triggerTypes: any;
  addingBadge: boolean;
  badgedCourses: any;
  unbadgedCourses: any;
  organisationTypes: any;
  organisationTypesById: any;
  triggerTypesById: any;
  smqAdmin: any;
  lastOrgId: any = "";
  orgsById: any = [];
  courses: any;
  badges: any;
  coursesById: any;
  badgesById: any;
  organisation: any;
  dontConnect() {
    this.readiness$.next({});
  }

  public menu: NbMenuService;
  public smqPassword: string;
  public smqUsername: string;
  public vhost: string;
  public window: TSWindow = {};
  public isGuestConnected: boolean;
  public smqGuest: any;
  public rabbitEndpoint: string;
  public accessToken: string;
  public createPayload: () => any;
  public whoAmI: any;
  public smqUser: any;
  public smqPayroll: any;
  public isAdmin: boolean;
  public isEmployee: boolean;
  public isPayroll: boolean;
  public isManager: boolean;
  public role: string;
  public phases: any;
  public applicant: any;
  public firstLoad: boolean;
  public customer: any;
  public employee: any;
  public timers: Subscription[] = [];
  public readiness$: BehaviorSubject<{}> = new BehaviorSubject(null);
  public organisationReady$: BehaviorSubject<any> = new BehaviorSubject(null);
  public sharedDataReady$: BehaviorSubject<any> = new BehaviorSubject(null);

  public onReady(): Observable<any> {
    return this.readiness$.pipe(
      filter((value) => !!value),
      share()
    );
  }

  private currentOrgMessageSource = new BehaviorSubject({});
  currentOrgObservable = this.currentOrgMessageSource.asObservable();
  currentOrganisation = null;

  public orgExists: boolean = false;

  private currentUnlockKeySource = new BehaviorSubject({});
  currentUnlockKeyObservable = this.currentUnlockKeySource.asObservable();

  constructor(private router: Router, public toastr: NbToastrService) {
    var self = this;
  }

  broadcastCurrentOrganisation(messages: any) {
    this.currentOrgMessageSource.next(messages);
    if (messages && messages.length) {
      this.orgExists = true;
    } else {
      this.orgExists = false;
    }
    console.error("Broadcast:", messages);
  }

  broadcastCurrentUnlockKey(message: any) {
    this.currentUnlockKeySource.next(message);
    // this.currentUnlockKey = message;
    // console.error("KeyBroadcast:", message)
  }

  async saveAccessToken(accessToken: string) {
    if (!accessToken) return;
    var gds = this;
    gds.accessToken = accessToken;
    // localStorage.setItem('accessToken', accessToken);
    if (!gds.whoAmI) {
      var waiReply = await gds.smqGuest.WhoAmI(gds.createPayload());
      if (this.hasNoErrors(waiReply)) {
        gds.whoAmI = waiReply.SingletonAppUser;
      }
    }
    gds.connect();
  }

  public logout() {
    this.isAdmin = false;
    this.isEmployee = false;
    this.whoAmI = null;
    Object.keys(this).forEach((key) => {
      if (this[key] instanceof BehaviorSubject) {
        this[key].next(null);
      }
    });
  }

  connect() {
    // console.error("LOADING ALL DATA");
    var gds = this;
    var currentRolesAssigned = gds.whoAmI ? gds.whoAmI.Roles : [];

    if (!gds.whoAmI || !currentRolesAssigned) {
      // console.error('ERROR AUTHENTICATING - WHOAMI: ', gds.whoAmI);
      return;
    }
    gds.isAdmin = false;
    gds.isEmployee = false;
    gds.isPayroll = false;

    if (currentRolesAssigned.indexOf("Admin") >= 0) {
      gds.role = "Admin";
      gds.isAdmin = true;
      gds.smqAdmin = generateAdminActor();
      gds.smqUser = generateOrganisationAdminActor();
    } else if (currentRolesAssigned.indexOf("OrganisationAdmin") >= 0) {
      gds.role = "OrganisationAdmin";
      gds.isEmployee = true;
      gds.smqUser = generateOrganisationAdminActor();
    }

    gds.smqUser.rabbitEndpoint = gds.rabbitEndpoint;

    if (gds.smqAdmin) {
      gds.smqAdmin.rabbitEndpoint = gds.rabbitEndpoint;
      gds.smqAdmin.connect(
        gds.vhost,
        gds.smqUsername,
        gds.smqPassword,
        function () {},
        function () {
          // console.error('Admin User Connected');
          gds.connectOrganisationAdminUser(gds);
        }
      );
    } else gds.connectOrganisationAdminUser(gds);
  }

  private connectOrganisationAdminUser(gds: this) {
    if (!gds.smqUser.client || !gds.smqUser.client.connected) {
      gds.smqUser.connect(
        gds.vhost,
        gds.smqUsername,
        gds.smqPassword,
        function () {},
        function () {
          gds.organisationAdminReady$.next(true);
        }
      );
    }

    gds.smqUser.createPayload =
      gds.smqUser.createPayload ||
      function () {
        return { AccessToken: gds.smqUser.accessToken || gds.accessToken };
      };
  }

  hasNoErrors(payload) {
    if (payload && !payload.ErrorMessage) return true;
    else {
      this.toastr.danger(payload.ErrorMessage, "Error");
    }
  }

  async connectToProxyServer(organisation) {
    var gds = this;
    if (organisation && !organisation.IsLocked) {
      if (!organisation.localUser || !organisation.localUser.client.connected) {
        if (organisation.localUser) {
          console.error("CONFIGURING ORGANISATION", organisation);
          organisation.localUser.disconnect();
        }
        organisation.localUser = generateOrganisationAdminActor();
        organisation.localUser.rabbitEndpoint = organisation.WSSRabbitEndpoint;
        organisation.localUser.connect(
          organisation.AMQPSVHost,
          organisation.AMQPSUsername,
          organisation.AMQPSPassword,
          function () {},
          function () {
            gds.loadSharedData(organisation);
            // gds.sharedDataReady$.next(true)
            gds.organisationReady$.next(organisation);
          }
        );
      } else if (!organisation.$badges) {
        gds.loadSharedData(organisation);
        // gds.sharedDataReady$.next(true)
        gds.organisationReady$.next(organisation);
      }
    }
  }

  async addCourseBadge(organisation, courseBadge, triggerType) {
    this.addingBadge = true;
    var payload = this.createPayload();
    payload.OrganisationId = organisation.OrganisationId;
    payload.Badge = courseBadge.Badge;
    payload.Course = courseBadge.Course;
    payload.TriggerType = triggerType;
    console.error("ADDING COURSE BADGE: ", payload);
    await organisation.localUser.SubmitCourseBadge(payload).then((reply) => {
      if (this.hasNoErrors(reply)) {
        console.error("ADDED NEW COURSE BADGE: ", reply);
        organisation.$courseBadges.push(reply.CourseBadge);
      }
      this.addingBadge = false;
    });
  }

  async removeCourseBadge(organisation, courseBadge) {
    console.error("TRYING TO REMOVE COURSE BADDGE: ", courseBadge);
    if (
      confirm(`Are you sure you want to Remove  badge '${courseBadge.Name}'?`)
    ) {
      this.addingBadge = true;
      var payload = this.createPayload();
      payload.CourseBadge = courseBadge;
      payload.OrganisationId = organisation.OrganisationId;
      var reply = await this.smqUser.DeleteCourseBadge(payload);
      if (this.hasNoErrors(reply)) {
        var index = organisation.$courseBadges.indexOf(courseBadge);
        organisation.$courseBadges.splice(index, 1);
        this.loadSharedData(this.organisation, true);
      }
      this.addingBadge = false;
    }
  }

  issueBadge(organisation, badgeCandidate) {
    console.error("TRYING TO ISSUE BADGE: ", badgeCandidate);
    var payload = this.createPayload();
    payload.OrganisationId = organisation.OrganisationId;
    payload.BadgeCandidate = badgeCandidate;
    organisation.localUser.IssueBadge(payload).then((reply) => {
      console.error("ISSUED BADGE SUCCESSFULLY: ", reply);
      badgeCandidate.IssuedBadgeExpanded = {};
    });
  }

  async loadSharedData(organisation = null, ignoreCache = false, issueAllEligibleBadges = false) {
    if (this.organisation && organisation) {
      if (this.organisation.OrganisationId == organisation.OrganisationId) {
        if (!ignoreCache) return;
      }
    }
    if (organisation) {
      this.organisation = organisation;
    }
    if (!organisation) {
      if (this.organisation) organisation = this.organisation;
    }
    console.error("LOADING STATIC DATA: ", this.smqUser);
    if (!this.smqUser) {
      this.router.navigateByUrl("/auth/login");
    } else if (!organisation)
      console.error(" ------     Proxy server still starting...");
    //  || !organisation.$proxyDMQueue
    else {
      let payload = this.createPayload();
      payload.LMSCurrentTermId = organisation.LMSCurrentTermId;
      payload.OrganisationId = organisation.OrganisationId;
      payload.IgnoreCache = ignoreCache;
      payload.IssueAllEligibleBadges = issueAllEligibleBadges;
      console.error("TRIGGER TYPES: ", this.triggerTypes);
      var badgeMapPayload = await organisation.localUser.LoadBadgeMap(payload);
      console.error("GOT BADGE MAP PAYLOAD: ", badgeMapPayload);
      if (badgeMapPayload.ErrorMessage) {
        this.toastr.show(badgeMapPayload.ErrorMessage, "Error", {
          status: "danger", duration: 10000,
        });
      } else {
        organisation.$courseBadges = badgeMapPayload.CourseBadges.sort(
          (a, b) => {
            if (a.Name > b.Name) return 1;
            else if (b.Name > a.Name) return -1;
            else return 0;
          }
        );

        organisation.$earnedCourseBadges = organisation.$courseBadges.filter(courseBadge => {
          var earnedCandidates = (courseBadge.BadgeCandidates || []).filter(badgeCandidate => badgeCandidate.BadgeEarned && !badgeCandidate.IssuedBadgeExpanded);
          courseBadge.$earnedBadgeCandidates = earnedCandidates;
          return earnedCandidates.length > 0;
        });
        console.error('COURSES WITH EARNED BADGES: ', organisation.$earnedCourseBadges)

        organisation.$courses = badgeMapPayload.LMSAllCourses;
        organisation.$coursesById = {};
        organisation.$badgedCourses = organisation.$courses.filter(
          (course) => course.AllCourseBadges && course.AllCourseBadges.length
        );
        organisation.$unbadgedCourses = organisation.$courses.filter(
          (course) => !course.AllCourseBadges || !course.AllCourseBadges.length
        );
        organisation.$courses.forEach((course) => {
          organisation.$coursesById[course.LMSCourseId] = course;
        });

        organisation.$badges = (badgeMapPayload.Badges || []).sort((a, b) => a.Name > b.Name ? 1 : -1);
        organisation.$badgesById = {};
        organisation.$badges.forEach((badge) => {
          organisation.$badgesById[badge.BadgeIdentifier] = badge;
        });

        organisation.terms = badgeMapPayload.LMSTerms;

        this.toastr.show("Done", "Shared data loaded");

        console.error(
          "GOT LMS DETAILS",
          organisation.$courseBadges,
          organisation.$courses,
          organisation.$coursesById,
          organisation.$badges,
          organisation.$badgesById
        );
      }
    }
  }
}
