import { Component, Inject, OnInit } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { NB_AUTH_OPTIONS } from '../../../@core/auth/auth.options';
import { getDeepFromObject } from '../../../@core/auth/helpers';

@Component({
  selector: 'ngx-change-password-widget',
  templateUrl: './change-password-widget.component.html',
  styleUrls: ['./change-password-widget.component.scss']
})
export class ChangePasswordWidgetComponent implements OnInit {

  user: { Password: string; };

  constructor(public dlgSvc : NbDialogRef<ChangePasswordWidgetComponent>,  
                @Inject(NB_AUTH_OPTIONS) protected options = {}) { }

  ngOnInit() {
    this.user = {
      Password: ""
    }
  }

  changePasssword() {
    if (this.user.Password) {
      this.dlgSvc.close(this.user.Password);
    }
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }

}
